<template>
  <div>
    <v-dialog v-model="dialog.dialogProgress" persistent width="300" eager>
      <v-card color="primary" dark>
        <v-card-text>
          {{progressMessage}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table
      v-model="vDataTable.selected"
      :headers="headers"
      :items="vDataTable.data"
      :search="vDataTable.search"
      fixed-header
      :expanded.sync="vDataTable.expanded"
      :page.sync="vDataTable.options.page"
      :itemsPerPage.sync="vDataTable.options.itemsPerPage"
      :options.sync="vDataTable.options"
      :height="$vuetify.breakpoint.height - 123 - offsetAppBar"
      :footer-props="{
        itemsPerPageText: $t('itemsPerPageText'),
        itemsPerPageAllText: $t('itemsPerPageAllText'),
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      item-key="skey"
      class="fill-height">
      <template v-slot:header.data-table-select="{ on, props }">
        <tr>
          <td>
            <v-simple-checkbox :ripple="false" v-bind="props" v-on="on"></v-simple-checkbox>
          </td>
          <td v-if="vDataTable.selected.length > 0">
            {{vDataTable.selected.length}}
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{$t('UserInformationMaint.UserInformationTitle')}}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <label>File
            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
          </label>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn outlined color="primary" @click="ImportExcelToDbConfirm" style="text-transform: capitalize">
            <img src="../../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>{{$t('UserInformationMaint.buttonImport')}}
          </v-btn>
          <v-divider class="mx-1" inset vertical></v-divider>
          <v-btn v-show="profile_name !== 'outsource'" class="text-right" outlined color="primary" style="text-transform: capitalize" @click="exportExcel">
            <img src="../../assets/SoftwareClinicIcon/Office-excel-xls-icon.png" height="30" weight="30"/>export excel
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field outlined dense hide-details v-model="vDataTable.search" append-icon="mdi-magnify" :label="$t('search')"></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.data-table-no="{ item, index }">
        {{((vDataTable.options.page - 1) * vDataTable.options.itemsPerPage) + index + 1}}
      </template>
      <template v-slot:no-data>
        <v-alert type="info" border="bottom" colored-border elevation="2">
          {{$t('noData')}}
        </v-alert>
      </template>
      <template v-slot:no-results>
        <v-alert type="warning">
          {{stringFormat($t('searchNotFound'),vDataTable.search)}}
        </v-alert>
      </template>
      <template v-slot:footer>
        <tr class="sticky-table-footer" style="position: absolute">
          <td>Selected({{vDataTable.selected.length}})s</td> 
        </tr>
      </template>
    </v-data-table>
    <v-tabs v-model="active" color="cyan" dark slider-color="yellow">
      <v-tab v-for="sheet in sheets" :key="sheet.sheetName" ripple>
        {{ sheet.sheetName }}
      </v-tab>
      <v-tab-item v-for="sheet in sheets" :key="sheet.sheetName">
        <v-data-table :headers="sheet.headers"
                      :items="sheet.data"
                      select-all
                      disable-pagination
                      item-key="0"
                      class="elevation-1">
          <template slot="headers" slot-scope="props">
            <tr>
                <th v-for="header in props.headers"
                    :key="header.text"
                    :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                    @click="changeSort(header.value)">
                  <v-icon small>arrow_upward</v-icon>
                    {{ header.text }}
                </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr :active="props.selected" @click="props.selected = !props.selected">
              <td v-for="col in filterColumnsRowNull(Object.keys(sheet.data[0]))" class="text-xs-left">{{ props.item[col] }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import Base64Binary from 'base64-arraybuffer'

/* import { consoleLog } from '../../../node_modules-/echarts/lib/util/log' */
export default {
  components: {
  },

  props: ['parentComponent'],

  data () {
    return {
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      rules: {
        winName: value => {
          const pattern = /^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/
          return pattern.test(value) || 'Invalid Window Name'
        },
      },
      dialog: {
        dialogProgress: false,
        dialogAddEdit: false,
        dialogLanguage: false,
      },
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          win_name: '',
          window_comment: ''
        },
        defaultItem: {
          win_name: '',
          window_comment: ''
        },
        editedLanguageIndex: -1,
        editedLanguageItem: {
        },
        defaultLanguageItem: {
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
        },
        expanded: [],
      },
      progressMessage: '',
      languages: [],
      languageEdit: true,
      sheets: [],
      file: '',
    }
  },

  computed: {
    ...mapGetters(['getShowAppBar', 'getAppBarHeight']),
    ...mapState({
      authLoading: state => state.auth.status === 'loading',
      name: state => `${state.user.profile.first_name} ${state.user.profile.last_name}`,
      userID: state => `${state.user.profile.user_id}`,
      picture_base64: state => `${state.user.profile.picture_base64}`,
      profile_name: state => `${state.user.profile.profile_name}`,
      token: state => `${state.auth.token}`,
    }),
    formTitle () {
      return this.vDataTable.editedIndex === -1 ? this.$t('UserInformationMaint.newBranchBcp') : this.$t('UserInformationMaint.newBranchBcp')
    },
    formLanguageTitle () {
      return this.vDataTable.editedLanguageIndex === -1 ? this.$t('UserInformationMaint.newBranchBcpLanguage') : this.$t('UserInformationMaint.editBranchBcpLanguage')
    },
    headers () {
      return [
        { text: 'name', value: 'branch', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'ref_code', align: 'left', value: 'user_id', class: 'text-xl-left body-1 black--text' },
        { text: 'street_1', value: 'address', align: 'left', class: 'text-xl-left body-1 black--text' },
        /* { text: 'tambon', value: 'tambon', align: 'left', class: 'text-xl-left body-1 black--text' }, */
        { text: 'tel', value: 'tel', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'amphur', value: 'amphur', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'sector', value: 'sector', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'provinces', value: 'provinces', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'zipcode', value: 'zipcode', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'create_user_id', value: 'create_user_id', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'create_date', value: 'create_date', align: 'left', class: 'text-xl-left body-1 black--text' },
      ]
    },
    offsetAppBar () {
      // return this.getShowAppBar ? 0 : this.getAppBarHeight
      return this.getShowAppBar ? this.getAppBarHeight : 0
    },
  },

  watch: {
    'dialog.dialogAddEdit' (val) {
      val || this.close()
    }
  },

  async created () {
    await this.retrieveUserInformation(this.appName)
  },

  mounted () {
  },

  updated () {
  },

  methods: {
    selectLanguage (language) {
    },
    initialize () {
      this.vDataTable.selected = []
      this.vDataTable.editedIndex = -1
      this.vDataTable.editedItem = {
        win_name: '',
        window_comment: ''
      }
      this.vDataTable.defaultItem = {
        win_name: '',
        window_comment: ''
      }
    },
    findCopyHeader (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      })
    },
    findLanguage (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      }).branch_bcp_language
    },
    findNodeSelectedLanguage (skey) {
      return this.vDataTable.data.find(function (x) {
        return (x.skey === skey)
      }).selectedLanguage
    },
    async retrieveUserInformation (appName) {
      console.log('retrieveUserInformation')
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/user_information/retrieveUserInformation' + process.env.VUE_APP_DATABASE_TYPE, {
        app_name: appName
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.data = response.data.data
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    toColumnName (num) {
      for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
        ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret
      }
      return ret
    },
    filterColumnsRowNull (cols) {
      return cols.filter(col => {
        return col !== 'Row_Null'
      })
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
    },
    async ImportExcelToDbConfirm (item) {
      console.log(this.file, 'this.file')
      if (this.file) {
        this.$swal({
          text: this.stringFormat(this.$t('UserInformationMaint.confirmImport'), item.name),
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then(async (result) => {
          if (result) {
            await this.ImportExcelToDb()
          }
        })
      } else {
        this.$swal({type: 'warning', title: this.$t('UserInformationMaint.messageSelectFile')})
      }
    },
    async ImportExcelToDb () {
      console.log('ImportExcelToDb')
      let formData = new FormData()
      formData.append('file', this.file)
      await this.axios.post(process.env.VUE_APP_API + '/user_information/ImportExcelToDb', formData,
        {
          headers: {'Content-Type': 'multipart/form-data'}
        }
      ).then(response => {
        if (response.data.isSuccess) {
          /* this.sheets = response.data.sheet */
          console.log(response.data, 'response.data')
          console.log(this.sheets, 'this.sheets')
          /* for (var i = 0; i < this.sheets.length; i++) {
            this.sheets[i].headers = []
            for (var val in this.sheets[i].data[0]) {
              var colName = (val === '0' ? '#' : this.toColumnName(parseInt(val)))
              if (val !== 'Row_Null') {
                this.sheets[i].headers.push({text: val, value: val, align: 'center'})
              }
            }
          } */
          console.log(response.data.reasonText, 'response.data.reasonText')
          this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          this.retrieveUserInformation()
        } else {
          this.$swal({type: 'error', title: response.data.reasonText})
        }
      })
      .catch(e => {
        this.$swal({type: 'error', title: e.message})
      })
    },
    exportExcel () {
      console.log(this.profile_name, 'profile_name')
      if (this.vDataTable.data.length >= 0) {
        this.axios.post(process.env.VUE_APP_API + '/user_information/ExportExcel', {data_json: this.vDataTable.data}
        ).then(response => {
          if (response.data.isSuccess) {
            const url = window.URL.createObjectURL(new Blob([Base64Binary.decode(response.data.excel_base64)], {type: 'application/vnd.ms-excel; charset=utf-8'}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'export_excel.xlsx')
            document.body.appendChild(link)
            link.click()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      } else {
        var messageCustom = 'No Data.'
        this.$swal({type: 'error', html: messageCustom})
      }
    },
  },
}
</script>
<style scoped>
.style-1 {
  color: rgb(215, 44, 44);
}
.style-2 {
  color: rgb(114,114,67);
}
.sticky-table-footer td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  border-top: thin solid rgba(0,0,0,.12);
}
</style>